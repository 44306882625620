html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}
.min-vh-80{
  height: 92vh !important;
}
.remove-top-left-radius-0 {
  border-top-left-radius: 0 !important;
}

.remove-top-right-radius-0 {
  border-top-right-radius: 0 !important;
}
.bg-custom-white{
  background-color: $custom-white;
}

#emoji-palette {
  top: -250px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.ql-toolbar.ql-snow{
  border: none !important;
  background-color: #F5F6FB !important;
}

.ql-container.ql-snow{
  border: none !important;
  background-color: #fff !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0.95rem;
  border-top-right-radius: 0.95rem;
}
#custom-dropdown-toggle::after {
  display: none;
}
.illustration-img{
  display: flex;
  height: 45vh;
  width: auto;
  max-width: 100%;
}

.bubble {
  position: relative;
  // background: #000000;
  background: white;
  text-align: center;
  border-radius: 0px;
  padding: 0px;
}
.bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 17px 20px 0;
  border-color: transparent white;
  top: 0%;
  left: -15px;
  margin-top: -10px;
}

.bubble-sender{
    position: relative;
    background:  #f8f6f2;
    text-align: center;
    border-radius: 0px;
    padding: 0px;
  }

  .bubble-sender:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 17px 20px 0 0;
    border-color:  #f8f6f2 transparent transparent transparent;
    top: 0%;
    right: -17px;
    // margin-top: -10px;
  }

  .chat_data{
    
position: absolute; 
right: 4%;
bottom: 1%;
color: #8E887A;
font-size: 0.8rem;
  }

.chat_date{
  display: inline-block;
  padding: 1rem;
  border: 1px dashed #DAD3C3;
  background-color: white;
}

#kt_chat_messenger_header{
  -webkit-box-shadow: -3.5px 4px 6px 3px #dddddd;
-moz-box-shadow: -3.5px 4px 6px 3px #dddddd;
box-shadow: -3.5px 4px 6px 3px #dddddd;
}

.bubble-sender img{
  max-width: 100%;
}

.bubble img{
  max-width: 100%;
}




.btn-pink {
  color: #ffffff;
  background-color: #FD305E;
  border-color: #FD305E;
}

.btn-pink:hover {
  color: #ffffff;
  background-color: #ff1851;
  border-color: #ff1851;
}

.btn-pink:focus,
.btn-pink.focus {
  color: #ffffff;
  background-color: #ff1851;
  border-color: #ff1851;
  box-shadow: 0 0 0 0.2rem rgba(253, 48, 94, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  color: #ffffff;
  background-color: #FD305E;
  border-color: #FD305E;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #ffffff;
  background-color: #ff1851;
  border-color: #ff1851;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 48, 94, 0.5);
}

// Error 404 Style

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

//dropup

#dropup-button-custom.dropdown-toggle::after {
  display: none; 
}

.ql-editor{
  max-height: 250px;
  overflow: auto;
}

.mt-8rem{
  margin-top: 8rem;
}

.dropdown-item{
  white-space: normal !important;
}

.square-image-container {
  position: relative;
  width: 100%; /* Change this to your desired square dimension */
  height: 300px; /* Change this to your desired square dimension */
  overflow: hidden;
}

.square-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  object-fit: contain;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;
  // overflow-y: hidden; // hide vertical
  // overflow-x: hidden; // hide horizontal

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}
@media screen and (max-width: 767px) {
  body {
    overflow: visible; /* or any other value appropriate for mobile views */
  }
}